/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */
.App{
  outline: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.viewer-spinner-circle {
  fill: none;
  stroke: #00afb9 !important;
  stroke-linecap: round;
  stroke-width: 2;
}

.MuiCircularProgress-colorPrimary {
  color:#00afb9 !important
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.loaderAdj{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 95vh;
 }


 .MuiInput-underline:after{
  border-bottom:2px solid #00AFB9 !important;
}

 .MuiCircularProgress-root{
   width:50px !important;
   height: 50px !important;
 }


/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
.bodyContent{
  margin: auto;
    width: 55%;
    padding: 20px;
     /* margin-top: 5%;  */
}
.Heading{
  padding-top: 3%;
  font-weight: bold !important;
  font-size: 1.4rem !important;
}
.downloadMessage{
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  /* font-weight: 500; */
  line-height: 1.5;
  color: #212529;
  text-align: left;
  /* background-color: #fff; */
  padding-top: 2%;
  padding-bottom: 3%;
}
.FormData{
  margin: auto;
  padding: 20px;
  padding-top: 10px;
  margin-top: -1%;
  margin-bottom: 50px;
  background-color: #fefefe;
  border-radius: 8px;
}
.formCheck{
  background: linear-gradient(90deg, #6fe2e9 2.97%, #00afb9 100%);
  border-radius: 10px;
  padding-top: 12px;
  width: 100%;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);
}
input[type=button]{
    background: #f07167;
    border-radius: 16px;
    border-radius: 8px;
    padding: 0.8em 6em;
    color: #fff;
    border: none;
    font-size: 16px;
    cursor: pointer;
    outline: none;
}
.MuiFormControl-root{
  width: 100%;
}
.MuiFormLabel-root.Mui-focused{
  color:#00AFB9 !important;
}
.viewer-toolbar-current-page-input{
  display: none !important;
}
input[type=text]{
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  /* border: 1px solid #ccc; */
  border-radius: 4px;
  box-sizing: border-box;
  color: #00AFB9 !important;
}
input[type=date]{
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.FooterId{
  position: absolute;
    bottom: 0;
    padding-top: 1%;
    padding-bottom: 1.5%;
    width: 100%;
    text-align: center;
    color: #a09999;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    border-top: 1px solid #cecece;
}
.footerimgadj{
  height: 28px;
  vertical-align: middle;
}

.headerHolder {

  /* position: fixed;
  z-index: 111111; */
    top: 0;
    width: 100%;
    background: white;
  display: flex;
  justify-content: space-between;
  padding: 1em 2em;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  align-items: center;
}

.rightImage img{
  cursor: pointer;
}

.lefImage img {
  max-height: 50px;
}

.footerHolder {
  /* position: fixed;
  bottom: 0; */
  height: 53.6px;
  max-height: 53.6px;
  display: flex;
  justify-content: space-between;
  padding: 1em 2em;
  width: 100%;
  color: white;
  text-align: center;
  box-shadow: 0px -3px 4px rgba(0, 0, 0, 0.25);
}

.paddingADJF{
  margin-left: 10px;
}

.copyRight{
  color:#00afb9;
}

.bannerRight {
  position: absolute;
  right: -32px;
  height: 78px;
  top: 54px;
}

.bannerHolder h1 {
  margin-top: 0.5em;
  font-size: 1.4em;
}
.bannerHolder {
  margin: 0 auto;
  width: calc(100% - 4em);
  background: linear-gradient(90deg, #6fe2e9 2.97%, #00afb9 100%);
  border-radius: 20px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 1em 2em;

  /* margin-top: 2em;
  margin-bottom: 2em; */
  margin-top: 1.5em;
  margin-bottom: 1.5em;

}

.bannerHolder h1 {
  margin-top: 0.5em;
}

.bannerImage {
  position: relative;
}

.bannerRight {
  position: absolute;
  right: 10px;
  height: 168px;
  top: -35px;
}

button:focus{
  outline: none !important;
}

.react-tel-input .form-control{
  width: inherit !important;
}

.MuiSvgIcon-root{
  fill: #00afb9 !important;
}

a{
  color: #f07167 !important
}

@media only screen and (max-width:700px) {
.bodyContent{
  width: 100%;
}
}