.troubleForm{
    /* padding-top:8em; */
    min-height: 100vh;
    padding-bottom:2em;
    margin-top:4em;

}

label{
    margin-bottom: unset !important;
}

#demo-simple-select-label{
    margin-bottom:0.5em !important;
}

.MuiFormControlLabel-root{

}

#standard-required,#date-picker-dialog{
    padding:unset;
}
#date-picker-dialog{
    /* margin-top:1em; */
}

.MuiFormControl-marginNormal{
    margin-top:unset !important;
}
.troubleContainer{
    margin:0 auto;
    width:58%;


    background: linear-gradient(
90deg
, #6fe2e9 2.97%, #00afb9 100%);
    border-radius: 10px;
    padding-top: 12px;
  
    box-shadow: 0px 4px 9px rgb(0 0 0 / 25%);
}

.realTroubleHolder{
    margin: auto;
    padding: 2em 1em;
    padding-top: 10px;
  
    margin-bottom: 50px;
    background-color: #fefefe;
    border-radius: 8px;
}

.submitTrouble{
    margin-top:3em;
    
}

.submitTroubleHolder{
    display: flex;
    justify-content: center;
    width: 100%;
}

.submitTroubleHolder>div:nth-child(1){
    margin-right: 1em;
}

@media only screen and (max-width:768px){
    .troubleContainer {
        width:90%;
    }
    .submitTroubleHolder{
     
        flex-direction: column;
    align-items: center;
    }

    .submitTroubleHolder>div:nth-child(1){
        margin-right: unset;
    }
}